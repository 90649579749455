import React, { useEffect, useState } from 'react'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import defaults from 'lodash/fp/defaults'
import isEmpty from 'lodash/fp/isEmpty'
import set from 'lodash/set'

import './adressView.css'

const STORAGE_KEY = 'igepa-shop-adress-state'
const initialAdress = {
  customerName: '',
  ic: '',
  dic: '',
  company: '',
  phone: '',
  email: '',
  street: '',
  streetNo: '',
  city: '',
  zip: '',
}
let initialContractAdress = initialAdress
let initialDeliveryAdress = initialAdress
const storeAdressState = adressState =>
  localStorage.setItem(STORAGE_KEY, JSON.stringify(adressState))
const restoreAdressState = () => {
  const storedState = localStorage.getItem(STORAGE_KEY)
  return storedState ? JSON.parse(storedState) : {}
}

const ErrorText = ({ children }) => (
  <small id="password-desc" className="f7 lh-copy dark-red db mb2">
    {children}
  </small>
)

const AdressView = () => {
  const [initialized, setInitialized] = useState(false)

  useEffect(() => {
    const storedState = restoreAdressState()
    if (storedState.contractAdress) {
      initialContractAdress = storedState.contractAdress
    }
    if (storedState.deliveryAdress) {
      initialDeliveryAdress = storedState.deliveryAdress
    }
    setInitialized(true)
  })

  return (
    <>
      <div className="tc">
        <h2>Zadejte fakturační adresu</h2>
      </div>
      {initialized && (
        <Formik
          initialValues={{
            contractAdress: defaults(
              { isSameAsDelivery: true },
              initialContractAdress
            ),
            deliveryAdress: initialDeliveryAdress,
            agreement: false,
            personalPickup: false,
          }}
          validate={values => {
            storeAdressState(values)
            let errors = {}

            if (isEmpty(values.contractAdress.customerName)) {
              set(errors, 'contractAdress.customerName', 'Zadejte své jméno')
            }

            if (isEmpty(values.contractAdress.company)) {
              set(errors, 'contractAdress.company', 'Zadejte firmu')
            }

            if (isEmpty(values.contractAdress.ic)) {
              set(errors, 'contractAdress.ic', 'Zadejte IČ')
            }

            if (isEmpty(values.contractAdress.dic)) {
              set(errors, 'contractAdress.dic', 'Zadejte DIČ')
            }

            if (isEmpty(values.contractAdress.city)) {
              set(errors, 'contractAdress.city', 'Zadejte město')
            }

            if (!values.agreement) {
              set(
                errors,
                'agreement',
                'Potvrďte prosím souhlas s obchodními podmínkami'
              )
            }

            if (!values.agreementPersonal) {
              set(
                errors,
                'agreementPersonal',
                'Potvrďte prosím souhlas se zpracováním osobních údajů'
              )
            }

            if (isEmpty(values.contractAdress.phone)) {
              set(errors, 'contractAdress.phone', 'Zadejte své telefonní číslo')
            } else if (
              !/^(\+420)? ?[1-9][0-9]{2} ?[0-9]{3} ?[0-9]{3}$/i.test(
                values.contractAdress.phone
              )
            ) {
              set(
                errors,
                'contractAdress.phone',
                'Neplatný formát telefonního čísla'
              )
            }

            if (isEmpty(values.contractAdress.email)) {
              set(errors, 'contractAdress.email', 'Zadejte svůj email')
            } else if (
              !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(
                values.contractAdress.email
              )
            ) {
              set(errors, 'contractAdress.email', 'Neplatný formát emailu')
            }

            if (isEmpty(values.contractAdress.street)) {
              set(
                errors,
                'contractAdress.street',
                'Zadejte ulici a číslo popisné/orientační'
              )
            } else if (
              !/^\D*(([1-9][0-9]*[a-cA-C]?)\/)?([1-9][0-9]*[a-cA-C]?)/i.test(
                values.contractAdress.street
              )
            ) {
              set(
                errors,
                'contractAdress.street',
                'Neplatný formát ulice a čísla popisného/orientačního'
              )
            }

            if (isEmpty(values.contractAdress.zip)) {
              set(errors, 'contractAdress.zip', 'Zadejte PSČ')
            } else if (!/\d{3}\s?\d{2}/i.test(values.contractAdress.zip)) {
              set(errors, 'contractAdress.zip', 'Neplatný formát PSČ')
            }

            if (!values.contractAdress.isSameAsDelivery) {
              if (isEmpty(values.deliveryAdress.customerName)) {
                set(errors, 'deliveryAdress.customerName', 'Zadejte své jméno')
              }

              if (isEmpty(values.deliveryAdress.city)) {
                set(errors, 'deliveryAdress.city', 'Zadejte město')
              }

              if (isEmpty(values.deliveryAdress.phone)) {
                set(
                  errors,
                  'deliveryAdress.phone',
                  'Zadejte své telefonní číslo'
                )
              } else if (
                !/^(\+420)? ?[1-9][0-9]{2} ?[0-9]{3} ?[0-9]{3}$/i.test(
                  values.deliveryAdress.phone
                )
              ) {
                set(
                  errors,
                  'deliveryAdress.phone',
                  'Neplatný formát telefonního čísla'
                )
              }

              if (isEmpty(values.deliveryAdress.email)) {
                set(errors, 'deliveryAdress.email', 'Zadejte svůj email')
              } else if (
                !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(
                  values.deliveryAdress.email
                )
              ) {
                set(errors, 'deliveryAdress.email', 'Neplatný formát emailu')
              }

              if (isEmpty(values.deliveryAdress.street)) {
                set(
                  errors,
                  'deliveryAdress.street',
                  'Zadejte ulici a číslo popisné/orientační'
                )
              } else if (
                !/^\D*(([1-9][0-9]*[a-cA-C]?)\/)?([1-9][0-9]*[a-cA-C]?)/i.test(
                  values.deliveryAdress.street
                )
              ) {
                set(
                  errors,
                  'deliveryAdress.street',
                  'Neplatný formát ulice a čísla popisného/orientačního'
                )
              }

              if (isEmpty(values.deliveryAdress.zip)) {
                set(errors, 'deliveryAdress.zip', 'Zadejte PSČ')
              } else if (!/\d{3}\s?\d{2}/i.test(values.contractAdress.zip)) {
                set(errors, 'deliveryAdress.zip', 'Neplatný formát PSČ')
              }
            }

            return errors
          }}
          onSubmit={(values, { setSubmitting }) => {
            setTimeout(() => {
              fetch('/api/cart/setAddress', {
                headers: {
                  Accept: 'application/json',
                  'Content-Type': 'application/json',
                },
                method: 'POST',
                body: JSON.stringify(values),
              })
                .then(() => {
                  document.location.replace('/nakupni-kosik/odeslat')
                })
                .catch(e => {
                  console.error(e)
                  alert('Adresu se nepodařilo uložit')
                })
              setSubmitting(false)
            }, 400)
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            isValid,
          }) => (
            <>
              <Form className="measure center">
                <label
                  htmlFor="contractAdress.customerName"
                  className="f6 b db mb2"
                >
                  Jméno a příjmení<sup>*</sup>
                </label>
                <ErrorMessage
                  name="contractAdress.customerName"
                  component={ErrorText}
                />
                <Field
                  type="text"
                  name="contractAdress.customerName"
                  className="db border-box hover-black w-100 measure ba b--black-20 pa2 br2 mb2"
                />
                <label htmlFor="contractAdress.company" className="f6 b db mb2">
                  Firma<sup>*</sup>
                </label>
                <ErrorMessage
                  name="contractAdress.company"
                  component={ErrorText}
                />
                <Field
                  type="text"
                  name="contractAdress.company"
                  className="db border-box hover-black w-100 measure ba b--black-20 pa2 br2 mb2"
                />
                <label htmlFor="contractAdress.ic" className="f6 b db mb2">
                  IČ<sup>*</sup>
                </label>
                <Field
                  type="text"
                  name="contractAdress.ic"
                  className="db border-box hover-black w-100 measure ba b--black-20 pa2 br2 mb2"
                />
                <ErrorMessage name="contractAdress.ic" component={ErrorText} />
                <label htmlFor="contractAdress.dic" className="f6 b db mb2">
                  DIČ<sup>*</sup>
                </label>
                <Field
                  type="text"
                  name="contractAdress.dic"
                  className="db border-box hover-black w-100 measure ba b--black-20 pa2 br2 mb2"
                />
                <ErrorMessage name="contractAdress.dic" component={ErrorText} />
                <label htmlFor="contractAdress.phone" className="f6 b db mb2">
                  Telefon<sup>*</sup>
                </label>
                <ErrorMessage
                  name="contractAdress.phone"
                  component={ErrorText}
                />
                <Field
                  type="phone"
                  name="contractAdress.phone"
                  className="db border-box hover-black w-100 measure ba b--black-20 pa2 br2 mb2"
                />
                <label htmlFor="contractAdress.email" className="f6 b db mb2">
                  E-mail<sup>*</sup>
                </label>
                <ErrorMessage
                  name="contractAdress.email"
                  component={ErrorText}
                />
                <Field
                  type="email"
                  name="contractAdress.email"
                  className="db border-box hover-black w-100 measure ba b--black-20 pa2 br2 mb2"
                />
                <label htmlFor="contractAdress.street" className="f6 b db mb2">
                  Ulice a číslo popisné/orientační<sup>*</sup>
                </label>
                <ErrorMessage
                  name="contractAdress.street"
                  component={ErrorText}
                />
                <Field
                  type="text"
                  name="contractAdress.street"
                  className="db border-box hover-black w-100 measure ba b--black-20 pa2 br2 mb2"
                />
                <label htmlFor="contractAdress.city" className="f6 b db mb2">
                  Město<sup>*</sup>
                </label>
                <ErrorMessage
                  name="contractAdress.city"
                  component={ErrorText}
                />
                <Field
                  type="text"
                  name="contractAdress.city"
                  className="db border-box hover-black w-100 measure ba b--black-20 pa2 br2 mb2"
                />
                <label htmlFor="contractAdress.zip" className="f6 b db mb2">
                  PSČ<sup>*</sup>
                </label>
                <ErrorMessage name="contractAdress.zip" component={ErrorText} />
                <Field
                  type="text"
                  name="contractAdress.zip"
                  className="db border-box hover-black w-100 measure ba b--black-20 pa2 br2 mb2"
                />
                <Field
                  type="checkbox"
                  name="contractAdress.isSameAsDelivery"
                  checked={values.contractAdress.isSameAsDelivery}
                  className="border-box hover-black measure ba b--black-20 pa2 br2 mb2"
                />
                <label
                  htmlFor="contractAdress.isSameAsDelivery"
                  className="f6 b mb2 ph1"
                >
                  Doručovací adresa je shodná s fakturační adresou
                </label>
                <br />
                <Field
                  type="checkbox"
                  name="agreement"
                  checked={values.agreement}
                  className="border-box hover-black measure ba b--black-20 pa2 br2 mb2"
                />
                <label htmlFor="agreement" className="f6 b mb2 ph1">
                  Souhlasím s obchodními&nbsp;
                </label>
                <a className="f6" href="/obchodni-podminky">
                  podmínkami
                </a>
                <ErrorMessage name="agreement" component={ErrorText} />
                <br />
                <Field
                  type="checkbox"
                  name="agreementPersonal"
                  checked={values.agreementPersonal}
                  className="border-box hover-black measure ba b--black-20 pa2 br2 mb2"
                />
                <label htmlFor="agreementPersonal" className="f6 b mb2 ph1">
                  Souhlasím se zpracováním osobních&nbsp;
                </label>
                <a className="f6" href="/zpracovani-osobnich-udaju">
                  údajů
                </a>
                <ErrorMessage name="agreementPersonal" component={ErrorText} />
                <br />
                <Field
                  type="checkbox"
                  name="personalPickup"
                  checked={values.personalPickup}
                  className="border-box hover-black measure ba b--black-20 pa2 br2 mb2"
                />
                <label htmlFor="personalPickup" className="f6 b mb2 ph1">
                  Osobní odběr v Odolené vodě
                </label>
                <span className="f7 tooltip">
                  i
                  <span className="tooltiptext">
                    Igepa CZ s.r.o.
                    <br />
                    Ke Stadionu 400
                    <br />
                    250 70 Odolena Voda
                    <br />
                    Provozní doba: Po-pá: 8 - 16:30
                  </span>
                </span>
                {!values.contractAdress.isSameAsDelivery && (
                  <>
                    <div className="tc">
                      <h2>Zadejte adresu pro doručení</h2>
                    </div>
                    <label
                      htmlFor="deliveryAdress.customerName"
                      className="f6 b db mb2"
                    >
                      Jméno a příjmení<sup>*</sup>
                    </label>
                    <ErrorMessage
                      name="deliveryAdress.customerName"
                      component={ErrorText}
                    />
                    <Field
                      type="text"
                      name="deliveryAdress.customerName"
                      className="db border-box hover-black w-100 measure ba b--black-20 pa2 br2 mb2"
                    />
                    <label
                      htmlFor="deliveryAdress.phone"
                      className="f6 b db mb2"
                    >
                      Telefon<sup>*</sup>
                    </label>
                    <ErrorMessage
                      name="deliveryAdress.phone"
                      component={ErrorText}
                    />
                    <Field
                      type="phone"
                      name="deliveryAdress.phone"
                      className="db border-box hover-black w-100 measure ba b--black-20 pa2 br2 mb2"
                    />
                    <label
                      htmlFor="deliveryAdress.email"
                      className="f6 b db mb2"
                    >
                      E-mail<sup>*</sup>
                    </label>
                    <ErrorMessage
                      name="deliveryAdress.email"
                      component={ErrorText}
                    />
                    <Field
                      type="email"
                      name="deliveryAdress.email"
                      className="db border-box hover-black w-100 measure ba b--black-20 pa2 br2 mb2"
                    />
                    <label
                      htmlFor="deliveryAdress.street"
                      className="f6 b db mb2"
                    >
                      Ulice a číslo popisné/orientační<sup>*</sup>
                    </label>
                    <ErrorMessage
                      name="deliveryAdress.street"
                      component={ErrorText}
                    />
                    <Field
                      type="text"
                      name="deliveryAdress.street"
                      className="db border-box hover-black w-100 measure ba b--black-20 pa2 br2 mb2"
                    />
                    <label
                      htmlFor="deliveryAdress.city"
                      className="f6 b db mb2"
                    >
                      Město<sup>*</sup>
                    </label>
                    <ErrorMessage
                      name="deliveryAdress.city"
                      component={ErrorText}
                    />
                    <Field
                      type="text"
                      name="deliveryAdress.city"
                      className="db border-box hover-black w-100 measure ba b--black-20 pa2 br2 mb2"
                    />
                    <label htmlFor="deliveryAdress.zip" className="f6 b db mb2">
                      PSČ<sup>*</sup>
                    </label>
                    <ErrorMessage
                      name="deliveryAdress.zip"
                      component={ErrorText}
                    />
                    <Field
                      type="text"
                      name="deliveryAdress.zip"
                      className="db border-box hover-black w-100 measure ba b--black-20 pa2 br2 mb2"
                    />
                  </>
                )}
                <small>
                  Položky označené<sup>*</sup> jsou povinné
                </small>
                <div className="pv4">
                  <div className="dib w-50 fl tl">
                    <a
                      className="f6 grow no-underline br-pill ph3 pv2 mb2 dib white bg-dark-blue"
                      href="/nakupni-kosik"
                    >
                      Zpět
                    </a>
                  </div>
                  <div className="dib w-50 fr tr">
                    <button
                      className="f6 grow no-underline br-pill ph3 pv2 mb2 dib white bg-dark-blue"
                      href="/nakupni-kosik/odeslat"
                      type="submit"
                    >
                      Odeslat objednávku
                    </button>
                  </div>
                </div>
              </Form>
            </>
          )}
        </Formik>
      )}
    </>
  )
}

export default AdressView
