import React from 'react'

import Layout from '../../layouts/layout'
import AdressView from '../../components/ShopingCart/AdressView'

const AdressViewPage = () => (
  <Layout>
    {() => (
      <main className="w-100 pv2">
        <AdressView />
      </main>
    )}
  </Layout>
)

export default AdressViewPage
